@import "./v";

@media only screen and (max-width: 1440px) {
	.container {
		max-width: 1120px;
	}

	header {
		.row {
			nav {
				ul {
					gap: 52px;
					li {
						a {
							padding: 0 10px;
						}
					}
				}
			}
		}
	}
	footer {
		padding: 60px 0 40px 0;
	}

	// ====== Pages

	.welcome {
		.right {
			img {
				max-width: 420px;
			}
		}
	}

	// ======================== //

	.marquee,
	.introduction,
	.about-us,
	.video,
	.why-choose-us,
	.how-it-works,
	.faqs,
	.contact,
	.convinced,
	.the-people,
	.mission-vision {
		padding: 80px 0;
	}

	// ======================== //

	.video {
		.row {
			.left {
				.txt {
					max-width: 368px;
				}
			}
			.right {
				.box {
					height: 368px;
				}
			}
		}
	}
	.the-people {
		.all {
			grid-column-gap: 20px;
			.one {
				.i {
					height: 352px;
				}
			}
		}
	}
	.biography {
		.txt {
			width: 532px;
			padding: 40px;
		}
	}
	.mission-vision {
		.row {
			gap: 160px;
		}
	}
	.why-choose-us {
		.row {
			gap: 80px;
		}
	}
	.how-it-works {
		.all {
			margin-bottom: 40px;
		}
	}
	.contact {
		.row {
			.left {
				padding-right: 80px;
			}
		}
	}
	#common-page {
		.landing {
			padding: 160px 0 60px 0;
		}
	}
	.page-not-found {
		padding: 0;
	}
}

@media only screen and (max-width: 1180px) {
	.container {
		max-width: 860px;
	}
	header,
	header.fixed {
		padding: 0;
		background-color: white;
		@include vendorPrefix("box-shadow", "0 0 4px rgba(0,0,0,0.12)");
		@include vendorPrefix("transition-timing-function", "none");
		@include vendorPrefix("animation", "none");
		@include vendorPrefix("animation-fill-mode", "none");
		@include vendorPrefix("animation-delay", "none");
		z-index: 99;
		.container {
			max-width: 100%;
			padding-left: 16px;
			padding-right: 16px;
		}
		.row {
			.logo {
				svg {
					height: 40px;
					path.text {
						fill: $colorPrimary;
					}
				}
			}
			nav {
				margin-left: auto;
				ul {
					gap: 32px;
					li {
						a {
							line-height: 80px;
							padding: 0 10px;
							color: $colorPrimary;
						}
						a.active {
							color: $colorPrimary;
							&:after {
								display: block;
							}
						}
					}
				}
			}
		}
	}

	// ====== Pages

	.hero {
		height: auto;
		padding: 240px 0 180px 0;
	}
	.welcome {
		.row {
			height: auto;
			padding: 160px 0 120px 0;
		}
		.right {
			img {
				max-width: 348px;
			}
		}
		svg.svg-top,
		svg.svg-bottom {
			height: 160px;
		}
	}

	// ======================== //

	.marquee,
	.introduction,
	.about-us,
	.video,
	.why-choose-us,
	.how-it-works,
	.faqs,
	.contact,
	.convinced,
	.the-people,
	.mission-vision {
		padding: 68px 0;
	}

	// ======================== //

	.introduction {
		.row {
			align-items: flex-start;
			.left {
				padding-right: 92px;
			}
			.right {
				.txt {
					.btn {
						margin-top: 32px;
					}
				}
			}
		}
	}
	.about-us {
		.row {
			.btn {
				margin-top: 32px;
			}
		}
	}
	.video {
		.row {
			display: block;
			.left,
			.right {
				width: 100%;
			}
			.left {
				margin-bottom: 40px;
				.txt {
					max-width: 100%;
					.btn {
						display: none;
					}
				}
			}
		}
	}
	.why-choose-us {
		.row {
			display: block;
			.left,
			.right {
				width: 100%;
			}
			.left {
				margin-bottom: 40px;
			}
		}
	}
	.how-it-works {
		svg.svg-top,
		svg.svg-bottom {
			height: 160px;
		}
		.all {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(auto-fill, auto);
			grid-row-gap: 20px;
			grid-column-gap: 20px;
			max-width: 540px;
			margin: 0 auto;
			margin-top: 40px;
			margin-bottom: 40px;
		}
	}
	.contact {
		.row {
			gap: 80px;
			.left,
			.right {
				padding: 0;
			}
		}
	}
	.the-people {
		.all {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(auto-fill, auto);
			grid-row-gap: 40px;
			grid-column-gap: 20px;
			max-width: 540px;
			margin: 0 auto;
			margin-top: 40px;
		}
	}
	.mission-vision {
		svg.svg-bottom {
			height: 160px;
		}
		.row {
			gap: 80px;
		}
	}
	.about-us {
		svg.right {
			height: 160px;
		}
	}
	.convinced {
		svg.svg-top,
		svg.svg-bottom {
			height: 160px;
		}
	}
	#common-page {
		.landing {
			padding: 148px 0 60px 0;
		}
		.content-area {
			svg.svg-top,
			svg.svg-bottom {
				height: 160px;
			}
		}
	}
}

@media only screen and (max-width: 890px) {
	.container {
		max-width: 532px;
	}
	header,
	header.fixed {
		.row {
			.menu-btn {
				display: block;
			}
			.logo {
				padding: 12px 0;
				svg {
					height: 36px;
				}
			}
			nav {
				display: none;
			}
			nav.open {
				display: block;
				position: absolute;
				background-color: $colorPrimary;
				width: 268px;
				left: 0px;
				top: 60px;
				height: 100vh;
				overflow-y: scroll;
				padding: 80px 0 280px 0;
				z-index: 9999;
				@include vendorPrefix(
					"box-shadow",
					"0px 6px 20px rgba(0,0,0,.16)"
				);
				@include vendorPrefix("transition-timing-function", "ease-in");
				@include vendorPrefix("animation", "slide-from-left 0.4s");
				@include vendorPrefix("animation-fill-mode", "both");
				ul {
					display: block;
					li {
						margin: 0;
						border-bottom: solid 1px rgba(255, 255, 255, 0.04);
						&:first-child {
							border-top: solid 1px rgba(255, 255, 255, 0.04);
						}
						a {
							padding: 0 20px;
							line-height: 48px;
							height: 48px;
							color: white;
							&:after {
								// display: none;
								width: 4px;
								height: 100%;
							}
						}
					}
				}
			}
		}
	}

	footer {
		.container {
			max-width: 100%;
			padding-left: 16px;
			padding-right: 16px;
		}
		ul.menu {
			display: block;
			text-align: center;
			padding-bottom: 20px;
			border-bottom: solid 1px rgba(0, 0, 0, 0.12);
			li {
				&:after {
					display: none;
				}
				a {
					text-transform: none;
					font-size: 13px;
					line-height: 26px;
					display: block;
				}
			}
		}
	}

	button {
		padding: 0 12px;
	}

	// ====== Pages

	.hero {
		padding: 132px 0 132px 0;
		.txt {
			.logo {
				width: 168px;
			}
			.title {
				margin-top: 8px;
			}
			.description {
				margin: 12px 0 16px 0;
			}
		}
		.arr {
			bottom: 40px;
		}
	}
	.welcome {
		.row {
			padding: 120px 0 68px 0;
			display: block;
			.left {
				width: 100%;
			}
			.right {
				display: none;
			}
		}
		.txt {
			.title {
				font-size: 40px;
				span {
					font-size: 16px;
				}
			}
		}
		svg.svg-top,
		svg.svg-bottom {
			height: 128px;
		}
	}

	// ======================== //

	.marquee,
	.introduction,
	.about-us,
	.video,
	.why-choose-us,
	.how-it-works,
	.faqs,
	.contact,
	.convinced,
	.the-people,
	.mission-vision {
		padding: 60px 0;
	}

	// ======================== //

	.marquee {
		ul {
			li {
				font-size: 28px;
			}
		}
	}
	.introduction {
		.row {
			display: block;
			.left,
			.right {
				width: 100%;
				padding: 0;
			}
			.left {
				max-width: 248px;
				margin: 0 auto;
				margin-bottom: 32px;
			}
		}
	}
	.why-choose-us {
		.all {
			display: block;
			.one {
				padding: 16px 12px 16px 10px;
				margin-bottom: 8px;
				gap: 12px;
				.icon {
					width: 36px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.how-it-works {
		svg.svg-top,
		svg.svg-bottom {
			height: 120px;
		}
	}
	.contact {
		.row {
			display: block;
			.left,
			.right {
				width: 100%;
				padding: 0;
			}
			.left {
				margin-bottom: 60px;
			}
		}
	}
	.about-us {
		svg.right {
			height: 120px;
		}
		.row {
			.col-4-5 {
				width: 100%;
			}
			h1 {
				font-size: 28px;
				br {
					display: none;
				}
			}
		}
	}
	.the-people {
		.all {
			.one {
				.i {
					height: 348px;
				}
			}
		}
	}
	.mission-vision {
		svg.svg-bottom {
			height: 120px;
		}
		&:after {
			display: none;
		}
		.row {
			display: block;
			.left,
			.right {
				width: 100%;
			}
			.left {
				margin-bottom: 60px;
			}
		}
	}
	.convinced {
		svg.svg-top,
		svg.svg-bottom {
			height: 120px;
		}
	}
	.video {
		.row {
			.right {
				.box {
					height: 268px;
					svg.play {
						height: 40px;
						width: 40px;
						margin-top: -20px;
						margin-left: -20px;
					}
				}
			}
		}
	}
	#common-page {
		.landing {
			padding: 120px 0 60px 0;
		}
		.content-area {
			padding: 60px 0;
			svg.svg-top,
			svg.svg-bottom {
				height: 120px;
			}
		}
	}
}

@media only screen and (max-width: 564px) {
	.container {
		max-width: 100%;
		padding-left: 16px;
		padding-right: 16px;
	}

	// ====== Pages

	.hero {
		padding: 120px 0 102px 0;
		.txt {
			max-width: 100%;
			padding-left: 16px;
			padding-right: 16px;
			.logo {
				display: none;
			}
			.title {
				display: block;
				text-align: center;
				h1 {
					font-size: 28px;
				}
				h4 {
					font-size: 18px;
				}
			}
			h5 {
				font-size: 16px;
				line-height: 1.2;
			}
			.btns {
				gap: 6px;
			}
		}
		.arr {
			margin-left: -10px;
			svg {
				width: 20px;
			}
		}
	}
	.welcome {
		.row {
			padding: 120px 0 60px 0;
		}
		.txt {
			.title {
				font-size: 32px;
				span {
					font-size: 13px;
				}
			}
		}
	}
	.marquee {
		padding: 40px 0;
		ul {
			gap: 10px;
			li {
				font-size: 20px;
			}
			li.sp {
				&:after {
					height: 4px;
					width: 4px;
					margin-left: -2px;
				}
			}
		}
	}
	.introduction {
		.row {
			.left {
				max-width: 186px;
				margin-bottom: 24px;
			}
		}
	}
	.how-it-works {
		.all {
			display: block;
			.one {
				width: 100%;
				display: flex;
				justify-content: flex-start;
				padding: 16px 12px 16px 10px;
				margin-bottom: 8px;
				gap: 12px;
				&:last-child {
					margin-bottom: 0;
				}
				.icon {
					width: 36px;
					height: 36px;
					padding: 6px;
				}
				.t {
					flex: 1;
					text-align: left;
					hr {
						display: none;
					}
				}
			}
		}
	}
	.video {
		.row {
			.right {
				.box {
					height: 186px;
				}
			}
		}
	}
	.faqs {
		.questions {
			.one {
				.question {
					padding: 24px 8px 24px 12px;
				}
			}
		}
	}
	.contact {
		.row {
			.left {
				table {
					tbody {
						tr {
							td {
								width: 100%;
								padding-right: 0;
								display: block;
								&:nth-child(2) {
									padding-left: 0;
								}
							}
						}
					}
				}
			}
		}
	}
	.convinced {
		.row {
			h4 {
				font-size: 18px;
			}
		}
	}
	.about-us {
		.row {
			h4 {
				font-size: 18px;
			}
		}
	}
	.the-people {
		.all {
			max-width: 280px;
			display: block;
			.one {
				margin-bottom: 32px;
				&:last-child {
					margin-bottom: 0;
				}
				.i {
					height: auto;
					margin-bottom: 10px;
					img {
						display: block;
					}
				}
				br {
					display: none;
				}
			}
		}
	}
	.biography {
		.txt {
			@include calc("width", "100% - 16px");
		}
	}
}
