@import "./v";

// ====== Home Page ====== //

hr.sep {
	outline: none;
	border: none;
	height: 4px;
	width: 32px;
	background-color: $colorSecondary;
}

// =========================== //
// ====== Home Page
// =========================== //

// ====== OPTION 1

.hero {
	height: 100vh;
	&:after {
		position: absolute;
		content: "";
		inset: 0;
		@include vendorPrefix(
			"background-image",
			"linear-gradient(to bottom, rgba(61, 51, 104, 1), rgba(0, 0, 0, 0.2))"
		);
	}
	.txt {
		position: relative;
		z-index: 3;
		max-width: 532px;
		.logo {
			height: auto;
			width: 232px;
			display: block;
			margin: 0 auto;
			svg {
				width: 100%;
				height: auto;
				display: block;
				path.text {
					fill: white;
				}
				path.green {
					fill: #5dc6b3;
				}
				path.lightblue {
					fill: #3b86b5;
				}
				path.darkblue {
					fill: #1e4ccb;
				}
			}
		}
		.title {
			gap: 12px;
			justify-content: center;
			align-items: baseline;
			margin-top: 16px;
		}
		.description {
			margin: 16px 0 32px 0;
		}
		.btns {
			gap: 12px;
		}
	}
	.arr {
		position: absolute;
		z-index: 4;
		bottom: 60px;
		left: 50%;
		margin-left: -14px;
		@include vendorPrefix("animation", "jump-infinite 2s infinite");
		svg {
			display: block;
			margin: 0 auto;
			width: 28px;
			height: auto;
			path {
				fill: white;
			}
		}
	}
}

// ====== OPTION 2

.welcome {
	background-color: $colorPrimary;
	@include vendorPrefix(
		"background-image",
		"linear-gradient(to right, #231d3d, #3d3368)"
	);
	.row {
		position: relative;
		z-index: 3;
		height: 100vh;
		max-width: 1280px;
		margin: 0 auto;
	}
	.txt {
		max-width: 532px;
		.title {
			font-size: 60px;
			span {
				font-size: 24px;
			}
		}
	}
	.right {
		img {
			max-width: 532px;
		}
	}
	svg.svg-top,
	svg.svg-bottom {
		position: absolute;
		z-index: 1;
		height: 320px;
		width: auto;
		display: block;
	}
}

.marquee {
	overflow: hidden;
	padding: 120px 0;
	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;
		li {
			font-size: 40px;
			list-style: none;
			line-height: 1.2;
			color: $colorPrimary;
			font-weight: 300;
			white-space: nowrap;
		}
		li.rs {
			color: $colorSecondary;
		}
		li.sp {
			position: relative;
			&:after {
				position: absolute;
				content: "";
				top: 50%;
				left: 50%;
				height: 8px;
				width: 8px;
				margin-top: 0px;
				margin-left: -4px;
				background-color: #f0f0f0;
				@include vendorPrefix("border-radius", "100%");
			}
		}
		&:nth-child(2n) {
			flex-direction: row-reverse;
			li {
				color: #d8d8d8;
			}
			li.rs {
				color: #e3e3e3;
			}
		}
	}
}

.introduction {
	background-color: #f3f3fa;
	padding: 120px 0;
	.row {
		.left {
			padding-right: 160px;
		}
		.right {
			.txt {
				max-width: 532px;
				.btn {
					margin-top: 48px;
				}
			}
		}
	}
}

// =========================== //
// ====== About Us
// =========================== //

.about-us {
	padding: 120px 0;
	.row {
		position: relative;
		z-index: 3;
		h1 {
			font-size: 48px;
			text-wrap: balance;
		}
		.btn {
			margin-top: 48px;
		}
	}
	svg.right {
		position: absolute;
		z-index: -1;
		height: 50%;
		width: auto;
		display: block;
		@include opacity(0.12, 12);
		bottom: 0;
		right: 0px;
	}
}

.the-people {
	padding: 120px 0;
	background-color: #fafafd;
	.top {
		max-width: 532px;
	}
	.all {
		margin-top: 60px;
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-column-gap: 60px;
		.one {
			.i {
				width: 100%;
				height: 400px;
				img {
					width: 100%;
					height: auto;
					display: none;
				}
			}
		}
	}
}

.biography {
	background-color: rgba(255, 255, 255, 0.86);
	position: fixed;
	inset: 0;
	overflow: hidden;
	.txt {
		position: fixed;
		top: 0;
		right: 0;
		width: 680px;
		background-color: white;
		@include vendorPrefix("box-shadow", "0 0 40px rgba(0,0,0,0.08)");
		height: 100vh;
		overflow-y: scroll;
		padding: 60px;
		@include vendorPrefix("transition-timing-function", "ease-in");
		@include vendorPrefix("animation", "bio-slide-from-right 0.4s");
		@include vendorPrefix("animation-fill-mode", "both");
		.con {
			font-size: 16px;
			line-height: 1.5;
		}
	}
}

.mission-vision {
	padding: 120px 0;
	&:after {
		position: absolute;
		height: 100%;
		width: 50%;
		top: 0;
		right: 0;
		content: "";
		background-color: $colorPrimary;
		@include opacity("0.08", "08");
	}
	.row {
		gap: 240px;
		position: relative;
		z-index: 3;
		.txt {
			max-width: 532px;
			.btn {
				margin-top: 48px;
			}
		}
	}
	svg.svg-bottom {
		position: absolute;
		z-index: -1;
		height: 50%;
		width: auto;
		display: block;
		@include opacity(0.12, 12);
		bottom: 0;
		left: 0;
	}
}

// =========================== //
// ====== Why Choose Us
// =========================== //

.why-choose-us {
	padding: 120px 0;
	background-color: #fafafd;
	.row {
		gap: 120px;
	}
	.all {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(auto-fill, auto);
		grid-row-gap: 20px;
		grid-column-gap: 20px;
		.one {
			background-color: white;
			@include vendorPrefix("border-radius", "10px");
			@include vendorPrefix("box-shadow", "0 3px 6px rgba(0,0,0,0.024)");
			display: flex;
			padding: 20px;
			gap: 12px;
			.icon {
				width: 40px;
				svg {
					height: auto;
					width: 100%;
					display: block;
					path {
						color: $colorPrimary;
					}
				}
			}
			.t {
				flex: 1;
				p {
					color: #373737;
					font-size: 14px;
					margin-top: 5px;
				}
			}
		}
	}
}

.convinced {
	padding: 120px 0;
	.row {
		position: relative;
		z-index: 3;
		.txt {
			max-width: 680px;
			.btn {
				margin-top: 48px;
			}
		}
	}
	svg.svg-top,
	svg.svg-bottom {
		position: absolute;
		z-index: -1;
		height: 50%;
		width: auto;
		display: block;
		@include opacity(0.12, 12);
	}
	svg.svg-top {
		top: 0;
		right: 0px;
	}
	svg.svg-bottom {
		bottom: 0;
		left: 0;
	}
}

// =========================== //
// ====== How it Works
// =========================== //

.how-it-works {
	padding: 120px 0;
	.row {
		position: relative;
		z-index: 3;
	}
	.top {
		max-width: 532px;
	}
	.all {
		margin-top: 60px;
		margin-bottom: 80px;
		max-width: 1080px;
		display: flex;
		gap: 40px;
		.one {
			width: 100%;
			padding: 32px 20px 48px 20px;
			background-color: #fff;
			@include vendorPrefix("border-radius", "10px");
			@include vendorPrefix("box-shadow", "0 0px 20px rgba(0,0,0,0.08)");
			.icon {
				height: 80px;
				width: 80px;
				background-color: $colorPrimary;
				background-color: white;
				background-color: #f3f3fa;
				padding: 16px;
				margin-bottom: 20px;
				svg {
					width: 100%;
					height: auto;
					display: block;
					path {
						fill: $colorPrimary;
					}
				}
			}
			.t {
				hr {
					width: 16px;
					margin: 12px auto;
				}
				p {
					color: #666;
					font-size: 14px;
				}
			}
		}
	}
	svg.svg-top,
	svg.svg-bottom {
		position: absolute;
		z-index: -1;
		height: 50%;
		width: auto;
		display: block;
		@include opacity(0.12, 12);
	}
	svg.svg-top {
		top: 0;
		right: 0px;
	}
	svg.svg-bottom {
		bottom: 0;
		left: 0;
	}
}

.video {
	padding: 120px 0;
	@include vendorPrefix("box-shadow", "inset 0px 0px 120px rgba(0,0,0,0.13)");
	.row {
		.left {
			.txt {
				max-width: 480px;
				.btn {
					margin-top: 48px;
				}
			}
		}
		.right {
			.box {
				height: 480px;
				margin: 0 auto;
				background-color: $colorSecondary;
				@include vendorPrefix("border-radius", "8px");
				position: relative;
				overflow: hidden;
				cursor: pointer;
				&:after {
					position: absolute;
					content: "";
					inset: 0;
					background-color: $colorSecondary;
					z-index: 2;
					@include opacity("0.8", "80");
				}
				svg.play {
					position: absolute;
					z-index: 3;
					top: 50%;
					left: 50%;
					height: 80px;
					width: 80px;
					margin-left: -40px;
					margin-top: -40px;
					cursor: pointer;
				}
				&:hover {
					svg.play {
						@include vendorPrefix("transform", "scale(1.24)");
					}
				}
			}
		}
	}
}

// =========================== //
// ====== FAQs
// =========================== //

.faqs {
	@include vendorPrefix("box-shadow", "inset 0px 0px 120px rgba(0,0,0,0.13)");
	padding: 120px 0;
	.top {
		max-width: 532px;
	}
	.questions {
		margin-top: 60px;
		max-width: 860px;
		.one {
			.question {
				cursor: pointer;
				padding: 24px 10px 24px 15px;
				display: flex;
				align-items: center;
				border-bottom: solid 1px;
				border-color: #d8d8d8;
				._t {
					flex: 1;
					line-height: 1;
				}
				._t-open {
					color: $colorPrimary;
					color: white;
				}
				._icon {
					height: 7.5px;
					width: 12px;
					@include vendorPrefix("transition", "all 0.2s");
					path {
						fill: $colorPrimary;
					}
				}
				._icon-open {
					@include vendorPrefix("transform", "rotate(180deg)");
					path {
						fill: white;
					}
				}
			}
			.question-open {
				background-color: $colorPrimary;
			}
			.answer {
				padding: 15px 20px;
				background-color: #fff;
				border-bottom: solid 1px;
				border-color: #d8d8d8;
			}
			&:first-child {
				.question {
					border-top: solid 1px;
					border-color: #d8d8d8;
				}
			}
		}
	}
}

// =========================== //
// ====== Contact
// =========================== //

.contact {
	padding: 120px 0;
	.row {
		max-width: 1080px;
		margin: 0 auto;
		.left {
			padding-right: 102px;
			table {
				display: table;
				width: 100%;
				border-collapse: collapse;
				tbody {
					tr {
						td {
							padding-bottom: 8px;
							padding-right: 4px;
							&:nth-child(2) {
								padding-left: 4px;
							}
						}
						td[colSpan="2"] {
							padding-right: 0;
						}
					}
				}
			}
		}
		.right {
			.txt {
				max-width: 532px;
			}
		}
	}
}

// =========================== //
// ====== Common Page
// =========================== //

#common-page {
	.landing {
		position: relative;
		padding: 192px 0 120px 0;
		&:after {
			position: absolute;
			content: "";
			inset: 0;
			@include vendorPrefix(
				"background-image",
				"linear-gradient(to bottom, rgba(61, 51, 104, 1), rgba(0, 0, 0, 0.2))"
			);
		}
		.row {
			position: relative;
			z-index: 3;
			.txt {
				max-width: 532px;
			}
		}
		hr {
			outline: none;
			border: none;
			background-color: $colorSecondary;
			height: 4px;
			width: 32px;
			margin: 0 auto;
		}
	}
	.content-area {
		padding: 80px 0;
		.small-container {
			max-width: 980px;
		}
		.row {
			position: relative;
			z-index: 3;
		}
		svg.svg-top,
		svg.svg-bottom {
			position: absolute;
			z-index: -1;
			height: 320px;
			width: auto;
			display: block;
			@include opacity(0.12, 12);
		}
	}
}

.page-not-found {
	padding: 80px 0;
	hr.line {
		outline: none;
		border: none;
		height: 102px;
		margin: 20px auto;
		width: 3px;
		background-color: #f0f0f0;
	}
}

// =========================== //
// ====== Video Player
// =========================== //

#video-player {
	position: fixed;
	inset: 0;
	z-index: 9999999;
	background: rgba(255, 255, 255, 0.86);
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	.close {
		height: 32px;
		width: 32px;
		background-color: $colorPrimary;
		@include vendorPrefix("border-radius", "100%");
		padding: 6px;
		position: absolute;
		top: 4px;
		right: 4px;
		z-index: 3;
		cursor: pointer;
		z-index: 999;
		svg {
			height: 100%;
			width: 100%;
			display: block;
			path {
				fill: white;
			}
		}
	}
	video {
		max-width: 100%;
		max-height: 100%;
		display: block;
		margin: 0 auto;
	}
}
